<template>
    <div class="center-content">
        <main class="main">
            <div class="header">
                <p class="title">受限目录申请表</p>
            </div>
            <div class="main-info">
                <a-form :form="form">
                    <section class="main-block">
                        <div class="main-title">
                            <SameStyleBox title="申请信息"></SameStyleBox>
                        </div>
                        <div class="apply-info-table">
                            <section class="apply-info-item">
                                <p class="">
                                    <span class="require">*</span>
                                    目录名称
                                </p>
                                <div class="apply-info-data">
                                    <span>{{ info.resName }}</span>
                                </div>
                            </section>
                            <section class="apply-info-item">
                                <p class="">
                                    <span class="require">*</span>
                                    关联应用
                                </p>
                                <div class="apply-info-data">
                                    <!-- <section
                                        style="color:#666;cursor:pointer"
                                        @click="
                                            () => {
                                                showAppChooseModal = true
                                            }
                                        "
                                    >
                                        {{ relateAppInfo && Object.keys(relateAppInfo).length ? relateAppInfo.appName : '请选择' }}
                                    </section> -->
                                    <a-form-item>
                                        <a-select
                                            placeholder="搜索关联应用"
                                            @change="handleRelateAppChange"
                                            v-decorator="[
                                                'relevanceApply',
                                                {
                                                    initialValue: Object.keys(applyInfo).length ? applyInfo.applicationId : '',
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: '未选择关联应用'
                                                        }
                                                    ]
                                                }
                                            ]"
                                        >
                                            <a-select-option :value="item.id" v-for="item in relateAppList" :key="item.id">
                                                {{ item.applicationName }}
                                            </a-select-option>
                                        </a-select>
                                    </a-form-item>

                                    <!-- <input type="text" placeholder="请选择" class="apply-info-input" /> -->
                                </div>
                            </section>
                            <section class="apply-info-item">
                                <p class="">
                                    <span class="require">*</span>
                                    申请人姓名
                                </p>
                                <div class="apply-info-data">
                                    <a-form-item>
                                        <a-input
                                            type="text"
                                            class="apply-info-input"
                                            placeholder="请填写申请人姓名"
                                            v-decorator="[
                                                'userName',
                                                {
                                                    initialValue: Object.keys(applyInfo).length ? applyInfo.name : userInfo.userName,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: '申请人姓名不能为空'
                                                        }
                                                    ]
                                                }
                                            ]"
                                        ></a-input>
                                    </a-form-item>
                                </div>
                            </section>
                            <section class="apply-info-item">
                                <p class="">
                                    <span class="require">*</span>
                                    申请人电话
                                </p>
                                <div class="apply-info-data">
                                    <a-form-item>
                                        <a-input
                                            class="apply-info-input"
                                            placeholder="请填写申请人电话"
                                            v-decorator="[
                                                'applyTel',
                                                {
                                                    initialValue: Object.keys(applyInfo).length ? applyInfo.phone : userInfo.mobile,
                                                    rules: [
                                                        {
                                                            pattern: /^1[0-9]{10}$/,
                                                            message: '请输入正确的手机号'
                                                        },
                                                        {
                                                            required: true,
                                                            message: '申请人电话不能为空'
                                                        }
                                                    ]
                                                }
                                            ]"
                                        ></a-input>
                                    </a-form-item>
                                </div>
                            </section>
                            <section class="apply-info-item">
                                <p class="">
                                    <span class="require">*</span>
                                    申请人邮箱
                                </p>
                                <div class="apply-info-data">
                                    <a-form-item>
                                        <a-input
                                            type="email"
                                            class="apply-info-input"
                                            placeholder="请填写申请人邮箱"
                                            v-decorator="[
                                                'email',
                                                {
                                                    initialValue: Object.keys(applyInfo).length ? applyInfo.email : userInfo.email,
                                                    rules: [
                                                        {
                                                            type: 'email',
                                                            message: '邮箱格式不正确'
                                                        },
                                                        {
                                                            required: true,
                                                            message: '申请人邮箱不能为空'
                                                        }
                                                    ]
                                                }
                                            ]"
                                        ></a-input>
                                    </a-form-item>
                                </div>
                            </section>
                            <section class="apply-info-item">
                                <p class="">
                                    数源部门
                                </p>
                                <div class="apply-info-data">
                                    <span>{{ info.idPocName }}</span>
                                </div>
                            </section>
                            <section class="apply-info-item">
                                <p class="">
                                    <span class="require">*</span>
                                    应用场景
                                </p>
                                <div class="apply-info-data">
                                    <a-form-item>
                                        <a-input
                                            type="text"
                                            class="apply-info-input"
                                            placeholder="请填写应用场景"
                                            v-decorator="[
                                                'applyScene',
                                                {
                                                    initialValue: Object.keys(applyInfo).length ? applyInfo.applyScene : applyScene,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: '应用场景不能为空'
                                                        }
                                                    ]
                                                }
                                            ]"
                                        ></a-input>
                                    </a-form-item>
                                </div>
                            </section>
                        </div>
                    </section>
                    <section class="main-block" v-if="parameterData.dataSource.length">
                        <div class="main-title">
                            <SameStyleBox title="服务使用信息"></SameStyleBox>
                        </div>
                        <div class="modal-table">
                            <a-table rowKey="nameCn" :columns="parameterData.columns" :pagination="false" :data-source="parameterData.dataSource" :scroll="{ x: 1500, y: 600 }">
                                <span slot="dataType" slot-scope="value">{{ getLable(value, labelOptions.dataTypeOptions) }}</span>
                                <span slot="key" slot-scope="value">{{ getLable(value, labelOptions.iskeyOptions) }}</span>
                                <span slot="blank" slot-scope="value">{{ getLable(value, labelOptions.isBlankOptions) }}</span>
                                <span slot="dic" slot-scope="value">{{ getLable(value, labelOptions.isDicOptions) }}</span>
                                <span slot="open" slot-scope="value">{{ getLable(value, labelOptions.isOpenOptions) }}</span>
                            </a-table>
                        </div>
                    </section>
                    <section class="main-block">
                        <div class="main-title">
                            <SameStyleBox title="附件材料"></SameStyleBox>
                        </div>
                        <div class="main-content">
                            <a-table rowKey="name" :columns="fileTable.columns" :pagination="false" :data-source="fileTable.fileList" class="file-table">
                                <template slot="fileName" slot-scope="text, record">
                                    <span>
                                        <i style="color:red">*</i>
                                        {{ record.fileName }}
                                    </span>
                                </template>
                                <template slot="actions" slot-scope="text, record">
                                    <template v-if="!record.fileUrl">
                                        <a-upload name="file" :action="''" :show-upload-list="false" :before-upload="beforeUpload" :customRequest="showUpload">
                                            <span class="mbtn btn_upload" @click="handleClickUpload(record)">上传附件</span>
                                        </a-upload>
                                        <span class="mbtn btn_upload" v-if="isShowDownloadBtn(record)" @click="downloadTemplate(record)">模板下载</span>
                                    </template>
                                    <template v-else>
                                        <div class="btn_history_tag" v-if="record.hasHistoryTag">前次申请材料</div>
                                        <span class="mbtn btn_upload" @click="preview(record.fileUrl)" style="max-width: 180px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;vertical-align: middle;" :title="record.originalName">
                                            {{ record.originalName }}
                                        </span>
                                        <span class="mbtn btn_remove" @click="handleClickRemoveFile(record)">删除</span>
                                    </template>
                                </template>
                            </a-table>
                        </div>
                    </section>
                </a-form>
            </div>
            <div class="footer">
                <a-button type="primary" class="btn-submit" @click="handleSubmit" :loading="hasSubmit">{{ hasSubmit ? '正在提交' : '提交申请' }}</a-button>
                <a-button class="btn-cancel" @click="handleCancel">取消</a-button>
            </div>
        </main>
        <!-- <ApplicationChooseModal :show="showAppChooseModal" @confirm="confirmChooseApp" @cancel="cancelChooseApp"></ApplicationChooseModal> -->
    </div>
</template>
<script>
// import ApplicationChooseModal from '../components/ApplicationChooseModal'
import { GetDicts, submitLimitCatalog, getTemplateDownLoadUrl, getRelateAppList, getCatalogApplyInfo } from '../api'
import { uploadFile } from '@/API/http'
import { showStateLabel, getLable } from '@/utils/tools'
import { getCookie } from '@/utils/cookie'
import { DataSetDetailList } from '@/views/OpenData/dict'
import { GetDetail } from '@/views/OpenData/api'
export default {
    components: {
        // ApplicationChooseModal
    },
    name: 'DataCatalogApplication',
    data() {
        return {
            id: '',
            info: {},
            applyInfo: {},
            hasSubmit: false, //防止重复点击
            hasApply: false, //用于判断申请成功后，是否重复点击申请
            userInfo: {},
            collectionStructure: '',
            relateAppInfo: {},
            dataInterface: {},
            labelOptions: {
                dataTypeOptions: [],
                iskeyOptions: [
                    {
                        label: '是',
                        value: '1'
                    },
                    {
                        label: '否',
                        value: '2'
                    }
                ],
                isBlankOptions: [
                    {
                        label: '是',
                        value: '1'
                    },
                    {
                        label: '否',
                        value: '2'
                    }
                ],
                isDicOptions: [
                    {
                        label: '否',
                        value: ''
                    },
                    {
                        label: '是',
                        value: '1'
                    },
                    {
                        label: '否',
                        value: '2'
                    }
                ],
                isOpenOptions: []
            },
            applyScene: '',
            relateAppList: [],
            parameterData: {
                columns: [],
                dataSource: []
            },
            currentChooseFile: '',
            fileTable: {
                templateResource: {},
                columns: [
                    {
                        title: '附件材料',
                        dataIndex: 'fileName',
                        scopedSlots: { customRender: 'fileName' }
                    },
                    {
                        title: '操作',
                        dataIndex: 'actions',
                        scopedSlots: { customRender: 'actions' },
                        width: '35%'
                    }
                ],
                fileList: [
                    { name: 'openDataApply', fileName: '数据开放申请表', fileUrl: '', fileType: '', originalName: '' },
                    { name: 'openDataRequire', fileName: '数据开放需求清单', fileUrl: '', fileType: '', originalName: '' },
                    { name: 'safeLevel', fileName: '安全等级保护证书', fileUrl: '', fileType: '', originalName: '' },
                    { name: 'creditReport', fileName: '信用报告', fileUrl: '', fileType: '', originalName: '' },
                    { name: 'dataSafePromise', fileName: '数据安全承诺书', fileUrl: '', fileType: '', originalName: '' },
                    { name: 'other', fileName: '其他相关资质和能力证明', fileUrl: '', fileType: '', originalName: '' }
                ]
            }
        }
    },
    created() {
        this.form = this.$form.createForm(this, { name: 'form_dias' })
        this.id = this.$route.params.id
    },
    mounted() {
        this.getRelateAppList()
        this.getTemplateDownLoadUrl()
        // this.getDetail()
        this.getUserInfo()
        this.GetDicts()
    },
    methods: {
        getLable,
        showStateLabel,
        isShowDownloadBtn(record) {
            // console.log('record.name=>', record)

            return record.name in this.fileTable.templateResource
        },
        downloadTemplate(record) {
            window.open(this.fileTable.templateResource[record.name])
        },
        getTemplateDownLoadUrl() {
            getTemplateDownLoadUrl().then(res => {
                // debugger
                this.fileTable.templateResource = res
            })
        },
        getRelateAppList() {
            let params = {
                param: {
                    applicationState: 3
                    // applicationName: '', //应用名称
                    // createBy: '', //创建人id
                    // endCreateTime: '', //结束时间
                    // startCreateTime: '' //开始时间
                }
            }
            getRelateAppList(params)
                .then(res => {
                    // console.log('getRelateAppList=>', res)
                    this.relateAppList = res.records || []
                    this.getDetail()
                })
                .catch(err => {
                    console.log(err)
                })
        },
        beforeUpload(file) {
            let fileName = file.name.toLowerCase()
            const isLt10M = file.size / 1024 / 1024 < 10
            if (!isLt10M) {
                this.$message.error('文件大小不得超过10MB!')
                return false
            }
            let accecptType = ['png', 'jpeg', 'jpg', 'bmp', 'word', 'excel', 'ppt', 'doc', 'xls', 'rar', 'zip', '7z']
            const isCorrectType = accecptType.some(item => {
                return fileName.includes(item)
            })
            if (!isCorrectType) {
                this.$message.error('文件格式不支持，请重新上传！')
                return false
            }
        },
        handleClickUpload(item) {
            //当前选中要上传的是哪一项
            this.currentChooseFile = item.name
        },
        handleClickRemoveFile(record) {
            //移除上传文件
            console.log('this.fileTable.fileList=>', this.fileTable.fileList)
            // debugger
            this.fileTable.fileList = this.fileTable.fileList.map(item => {
                if (item.name == record.name) {
                    item.fileUrl = ''
                    item.fileType = ''
                    delete item.hasHistoryTag
                }
                return item
            })
        },
        showUpload(data) {
            // debugger
            console.log('data=>', data)
            let formData = new FormData()
            formData.append('file', data.file)
            formData.append('defaultFileName', true)
            uploadFile(formData)
                .then(res => {
                    console.log('res=>', res)
                    let fileUrl = res.serverIpAndPort + res.serverPath
                    let fileType = res.fileName.substr(res.fileName.lastIndexOf('.') + 1)
                    let originalName = res.originalName
                    this.fileTable.fileList = this.fileTable.fileList.map(item => {
                        if (item.name == this.currentChooseFile) {
                            item.fileUrl = fileUrl
                            item.fileType = fileType
                            item.originalName = originalName
                        }
                        return item
                    })
                })
                .catch(err => {})
        },
        onSelectParamsChange(item) {
            console.log(item)
            this.selectedRowKeys
        },
        getDetail() {
            let _this = this
            getCatalogApplyInfo({ catalogId: this.$route.query.id }).then(res => {
                if (res) {
                    _this.applyInfo = res
                    _this.relateAppInfo = _this._.find(_this.relateAppList, { id: res.applicationId })
                    _this.fileTable.fileList = JSON.parse(res.fileList)
                    _this.fileTable.fileList.map(item => {
                        item.hasHistoryTag = !!item.fileUrl
                        return item
                    })
                    console.log('_this.fileTable.fileList=>', _this.fileTable.fileList)
                } else {
                    _this.applyInfo = {}
                }
            })
            GetDetail({ id: this.$route.query.id }).then(res => {
                this.collectionStructure = res.collectionStructure
                let data = eval(res.collectionStructure)
                Object.keys(DataSetDetailList).forEach(item => {
                    _this.parameterData.columns.push({
                        title: DataSetDetailList[item],
                        dataIndex: item,
                        key: item,
                        width: 140,
                        fixed: item == 'name' ? 'left' : '',
                        scopedSlots: { customRender: item }
                    })
                })
                _this.info = res
                // debugger
                _this.parameterData.dataSource = data
                // debugger
            })
        },
        getUserInfo() {
            let data = getCookie('KF_USER')
            if (!data) {
                this.$message.warn('用户信息获取失败')
                this.userInfo = {}
                return
            }
            this.userInfo = JSON.parse(data) || {}
        },
        handleRelateAppChange(value) {
            this.relateAppInfo = this._.find(this.relateAppList, { id: value })
            console.log(this.relateAppInfo)
        },
        showGoAddAppConfirm() {
            let _this = this
            this.$info({
                title: '您还未注册应用，无法使用接口申请！',
                closable: true,
                okText: '前往应用注册',
                maskClosable: true,
                cancelText: '取消',
                onOk() {
                    _this.$router.push({
                        name: 'MyAppList'
                    })
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
        preview(url) {
            window.open(url)
        },
        handleSubmit(e) {
            if (this.hasSubmit) return //重复点击处理

            if (this.hasApply) {
                this.$message.warn('请勿重复申请！')
                return
            }
            e.preventDefault()
            let _this = this
            this.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    if (!_this.relateAppList.length) {
                        //已登录，未申请接口，但是该账号未关联应用，弹出提示去关联应用提示框随后跳到新增关联应用页面
                        _this.showGoAddAppConfirm()
                        return
                    }
                    if (!Object.keys(_this.relateAppInfo).length) {
                        //未选择关联应用
                        _this.$message.error('请选择关联应用')
                        return
                    }

                    let fileAllUpload = this.fileTable.fileList.every(item => {
                        return !!item.fileUrl
                    })
                    if (!fileAllUpload) {
                        this.$message.error('附件材料未提供完全，请上传')
                        return
                    }

                    this.fileTable.fileList.map(item => {
                        delete item.hasHistoryTag
                        return item
                    })
                    let params = {
                        // id: '',
                        applyEmail: values.email,
                        // applyNu:'',
                        applyScene: values.applyScene,
                        applyTel: values.applyTel,
                        applyUserId: _this.userInfo.id,
                        applyUserName: values.userName,
                        catalogId: _this.$route.query.id,
                        // fieldName: _this.collectionStructure,
                        catalogName: _this.info.resName,
                        resourceName: _this.info.resName,
                        infoName: _this.info.resName,
                        fileList: _this.fileTable.fileList,
                        orgId: _this.userInfo.orgId,
                        orgName: _this.info.idPocName,
                        applicationId: _this.relateAppInfo.id
                    }
                    this.hasSubmit = true

                    submitLimitCatalog(params)
                        .then(res => {
                            this.hasSubmit = false
                            this.hasApply = true
                            this.$message.success('提交成功')
                            this.$router.go(-1)
                        })
                        .catch(() => {
                            this.hasSubmit = false
                            this.hasApply = false
                        })
                    // console.log('Received values of form: ', values)
                    // let params = {
                    //     applicantEmail: values.email,
                    //     applicantId: _this.userInfo.id,
                    //     applicantName: _this.userInfo.userName,
                    //     applicantPhone: _this.userInfo.mobile,
                    //     applicationId: _this.relateAppInfo.id,
                    //     appKey: _this.relateAppInfo.appkey,
                    //     applicationName: _this.relateAppInfo.applicationName,
                    //     applyDepartment: _this.userInfo.orgId,
                    //     applyDepartmentName: _this.userInfo.orgName,
                    //     applyformName: values.title,
                    //     callnumberPerday: values.dayLimit,
                    //     callnumberPertime: values.singleNums,
                    //     callnumberTotal: values.allLimit,
                    //     interfaceId: _this.dataInterface.id,
                    //     interfaceName: _this.dataInterface.name,
                    //     purpose: _this.purpose
                    // }

                    // console.log('params=>', params)
                }
            })
        },
        handleCancel() {
            //点击下方取消
            this.$router.go(-1)
        },
        GetDicts() {
            // business_code0:业务类型,interface_protocol:协议(http等),call_mode:接口调用方式(get,post),coding:编码格式(utf-8等)
            let params = {
                types: 'public_property, interface_state, interface_protocol, call_mode,isOpen,dataType'
            }
            GetDicts(params)
                .then(res => {
                    // console.log('GetDicts=>', res)
                    this.labelOptions.isOpenOptions = res.isOpen
                    this.labelOptions.dataTypeOptions = res.dataType
                })
                .catch(err => {
                    // console.log(err)
                })
        }
    }
}
</script>
<style scoped lang="scss">
.center-content {
    padding-top: 24px;
    padding-bottom: 48px;
    .main {
        padding: 40px;
        background: #fff;
        .header {
            display: flex;
            flex-flow: row nowrap;
            padding-bottom: 27px;
            border-bottom: 2px solid #ebeced;
            .title {
                color: #000025;
                font-size: 30px;
                font-weight: bold;
                padding-right: 14px;
            }
        }
        .main-info {
            .main-block {
                margin-bottom: 18px;
                .interface-box {
                    .interface-card {
                        background-color: #ffffff;
                        border: solid 1px #cccccc;
                        padding: 20px 30px;
                        .interface-header {
                            display: flex;
                            align-items: center;
                            aside {
                                width: 40px;
                                height: 40px;
                                border: solid 1px rgba(193, 200, 210, 0.5);
                                overflow: hidden;
                                img {
                                    max-width: 100%;
                                    height: auto;
                                    vertical-align: top;
                                }
                            }
                            .interface-title {
                                font-size: 16px;
                                letter-spacing: 1px;
                                color: #0184ff;
                                margin-left: 16px;
                                font-weight: bold;
                            }
                            .interface-tags {
                                display: flex;
                                align-items: center;
                                margin-left: 34px;
                                flex-shrink: 0;
                                .tag {
                                    padding: 6px 14px;
                                    border-radius: 4px;
                                    font-size: 12px;
                                }
                                .tag-normal {
                                    background-color: rgba(24, 144, 255, 0.1);
                                    color: #1890ff;
                                }
                                .tag-success {
                                    background-color: rgba(19, 194, 194, 0.1);
                                    color: #13c2c2;
                                }
                                .tag-warning {
                                    background-color: rgba(255, 1, 1, 0.1);
                                    color: #ff0101;
                                }
                                .mgr16 {
                                    margin-right: 16px;
                                }
                            }
                        }
                        .interface-selections {
                            display: flex;
                            justify-content: space-between;
                            margin-top: 30px;
                        }
                    }
                }
                .apply-info-table {
                    display: flex;
                    flex-flow: row wrap;
                    border-top: 1px solid #d5d6e3;
                    border-bottom: none;
                    .apply-info-item {
                        display: flex;
                        width: 50%;
                        flex-shrink: 0;
                        align-content: center;
                        /* border: 1px solid #d5d6e3; */
                        height: 50px;
                        line-height: 50px;
                        padding: 0 20px;
                        border-bottom: 1px solid #d5d6e3;
                        &:first-of-type {
                            width: 100%;
                            .apply-info-data {
                                /* margin-left: 46px; */
                                width: 100%;
                            }
                        }
                        &:last-of-type {
                            /* width: 100%; */
                            border-right: 1px solid #d5d6e3;
                        }
                        &:nth-of-type(odd) {
                            border-right: 1px solid #d5d6e3;
                            border-left: 1px solid #d5d6e3;
                        }
                        &:nth-of-type(even) {
                            border-left: 1px solid #d5d6e3;
                        }
                        > p {
                            position: relative;
                            flex-shrink: 0;
                            color: #000025;
                            font-size: $fs12;
                            width: 90px;
                            .require {
                                position: absolute;
                                left: -8px;
                                top: 0;
                                color: #ff0000;
                            }
                        }
                        .apply-info-data {
                            margin-left: auto;
                            /* width: 420px; */
                            flex: 1;
                            .apply-info-input {
                                width: 100%;
                                background: transparent;
                                border: none;
                                outline: none;
                                box-shadow: none;
                                color: rgba(0, 0, 0, 0.65);
                            }
                            /deep/ .ant-select-selection__rendered {
                                margin-left: 0px;
                            }
                        }
                    }
                }
                .use-limit {
                    display: flex;
                    justify-content: space-between;
                    .limit-item {
                        display: flex;
                        align-items: center;
                        margin-left: 12px;
                        > p {
                            margin-right: 10px;
                            font-size: $fs12;
                            color: #000025;
                        }
                        .limit-nums {
                            width: 140px;
                            height: 30px;
                            background-color: #ffffff;
                            border: solid 1px #cccccc;
                            padding: 0 10px;
                            display: flex;
                            align-items: center;
                            /deep/ .ant-input-number {
                                border: none;
                                background: transparent;
                                outline: none;
                                box-shadow: none;
                                height: auto;
                            }
                            /deep/ .ant-form-explain {
                                left: -12px;
                                bottom: -10px;
                                white-space: nowrap;
                            }
                            > span {
                                margin-left: auto;
                                font-size: $fs12;
                                color: #000025;
                            }
                        }
                    }
                }
                .whitelist {
                    table {
                        width: 100%;
                        border-left: solid 1px #d5d6e3;
                        border-right: solid 1px #d5d6e3;
                        tr {
                            border-bottom: 1px solid #d5d6e3;
                            font-size: $fs12;
                            &:first-of-type {
                                border-top: 1px solid #d5d6e3;
                            }
                            th {
                                width: 50%;
                                height: 30px;
                                padding: 0 30px;
                                line-height: 30px;
                                background-color: #eef1f8;
                            }
                            td {
                                padding: 0 30px;
                                height: 30px;
                                line-height: 30px;
                            }
                        }
                    }
                }
                .file-table {
                    ::v-deep td {
                        /* display: flex;
                        align-items: center; */
                        padding: 10px 16px;
                        font-size: $fs14;
                    }
                    ::v-deep .ant-upload-list {
                        display: none;
                    }
                    .mbtn {
                        padding: 12px 24px;
                        cursor: pointer;
                        &:first-of-type {
                            padding-left: 0;
                        }
                    }
                    .btn_history_tag {
                        display: inline-block;
                        color: #999;
                        background: #f4f4f4;
                        padding: 2px 6px;
                        font-size: 12px;
                        border: 1px solid #ccc;
                        border-radius: 4px;
                        margin-right: 10px;
                    }
                    .btn_upload {
                        color: $blue;
                    }
                    .btn_remove {
                        color: red;
                    }
                }
            }
        }
        /deep/ .ant-form-item {
            margin-bottom: 0;
            height: 50px;
            line-height: 50px;
        }
        /deep/ .ant-form-item-control {
            line-height: inherit;
        }
        /deep/ .ant-form-explain {
            position: absolute;
            bottom: 4px;
            font-size: 12px;
        }
        /deep/ .ant-form-item-children {
            vertical-align: super;
        }
        /deep/ .apply-info-input {
            padding-left: 0;
        }
        /deep/ .ant-select-selection {
            border: 1px solid transparent;
            box-shadow: none;
            background-color: transparent;
            outline: none;
        }
        .footer {
            display: flex;
            justify-content: center;
            margin-top: 80px;
            button {
                margin: 0 14px;
                height: 40px;
                line-height: 40px;
                border-radius: 4px;
            }
            .btn-cancel {
                background-color: #f4f4f4;
                border: solid 1px #dddddd;
                color: #999999;
            }
        }
    }
}
</style>
