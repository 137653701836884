<template>
  <div >
      <div class="chart-wrap">
         <main id="my-chart">
         </main>

      </div>

  </div>
</template>

<script>
import echarts from "echarts";
export default {
    props: {
        chartsData: {
            type: Object,
            required:true
        }
    },
    data() {
        return {

        }
    },
    watch: {
         chartsData: {
           handler(newValue, oldValue) {
             this. chartsData = newValue;  //把新值赋值给我们的属性数据
             this.drawPie()  //刷新echarts图表
           },
           deep: true
            }
         },
    created () {
             
    },
    mounted() {
        this.$nextTick(() =>{
            this.drawPie()
        })
    },
    methods: {
        drawPie () {
            var myChart = echarts.init(document.getElementById('my-chart'))
            var colorList = ["#99ccff"];
            myChart.setOption({
                     title: {
                         text: '分析结果',
                         subtext: '单位个',
                         left: 'center'
                     },
                     tooltip: {
                         trigger: 'item',
                         formatter: '{b} : {c} ({d}%)'
                     },
                     legend: {
                          type: 'scroll',
                           bottom: 10,
                           left: 'center',
                           data: this.chartsData.xData,
                            formatter: function(value) {
                            var res = value // 长度超过6个的以省略号显示
                            if (res.length > 6) {
                                res = res.substring(0, 7) + '..'
                            }
                            return res
                        }  
                     },
                     series: [{
                                itemStyle: {
                                 normal: {
                                // color: function(params) {
                                // return colorList[params.dataIndex]
                                //     },
                                       }
                               },
                               type: 'pie',
                               radius: ['0', '60%'],
                            //    radius: '65%',
                               center: ['50%', '50%'],
                               selectedMode: 'single',
                               label: {
                                   normal: {
                                         position: 'inner',
                                          show : false
                                        }
                               },
                               data:this.chartsData.pieData,
                               emphasis: {
                                   itemStyle: {
                                       shadowBlur: 10,
                                       shadowOffsetX: 0,
                                       shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                         },
                        //  外环的背景色
                                 {
                                         itemStyle: {
                                             normal: {
                                                 color: 'rgba(62,109,255,0.2)',
                                             }
                                         },
                                         type: 'pie',
                                         hoverAnimation: false,
                                         radius: ['35%', '65%'],
                                         center: ["50%", "50%"],
                                         label: {
                                             normal: {
                                                 show:false
                                             }
                                         },
                                         data: [{
                                                 value: 1,
                                             }],
                                         z:-1
                               }
                         ]
                 }
              )
         }
    }

}
</script>

<style scoped lang="scss" >
.chart-wrap{
    width: 748px;
    height: 538px;
     border: 1px solid #E5E5E5;
     margin-left: 10px;
     main {
        width: 748px;
        height: 538px;
     }
}

</style>