<template>
    <div>
        <div class="chart-wrap">
            <main id="my-chart"></main>
        </div>
    </div>
</template>

<script>
import echarts from 'echarts'

export default {
    props: {
        chartsData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {}
    },
    watch: {
        chartsData: {
            handler(newValue, oldValue) {
                this.chartsData = newValue //把新值赋值给我们的属性数据
                this.drawPillar() //刷新echarts图表
            },
            deep: true
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.drawPillar()
        })
    },
    methods: {
        drawPillar() {
            var myChart = echarts.init(document.getElementById('my-chart'))
            myChart.setOption({
                title: {
                    text: '分析结果',
                    subtext: '单位个',
                    left: 'left'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                    }
                },

                xAxis: {
                    axisLabel: {
                        interval: 0, //坐标刻度之间的显示间隔，默认就可以了（默认是不重叠）
                        rotate: 38,
                        formatter: function(value) {
                            var res = value // 长度超过6个的以省略号显示
                            if (res.length > 6) {
                                res = res.substring(0, 7) + '..'
                            }
                            return res
                        }
                        //调整数值改变倾斜的幅度（范围-90到90）
                    },
                    type: 'category',
                    data: this.chartsData.xData
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: this.chartsData.yData,
                        type: 'bar',
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(
                                    0,
                                    1,
                                    0,
                                    0,
                                    [
                                        {
                                            offset: 0,
                                            color: '#fff' // 0% 处的颜色
                                        },
                                        {
                                            offset: 1,
                                            color: '#357df1' // 100% 处的颜色
                                        }
                                    ],
                                    false
                                )
                            }
                        }
                    }
                ]
            })
        }
    }
}
</script>

<style scoped lang="scss" >
.chart-wrap {
    width: 748px;
    height: 538px;
    border: 1px solid #e5e5e5;
    margin-left: 10px;
    main {
        width: 748px;
        height: 538px;
    }
}
</style>