<template>
    <div class="center-content">
        <login-modal v-if="openModal" @cancel="openModal = false"></login-modal>
        <div class="left-content">
            <!-- 左侧筛选 -->
            <div class="main">
                <div class="main-header">
                    <TabBar :list="typeTabs" @switchTab="switchTypeTab" class="order-tab"></TabBar>
                </div>
                <div class="left-list">
                    <div v-if="showScreeing == 'org'">
                        <div class="dept-tree-list">
                            <div class="dept-tree-item" v-for="(item,index) in deptTreeList" :key="index">
                                <div class="dept-tree-item-wrap">
                                    <div class="dept-tree-name" :class="{active:!searchData.idPoc && searchData.areaCode == item.areaCode}" @click.stop="onClickParentDept(item)">
                                        <div v-if="item.child && item.child.length" class="icon-caret">
                                            <a-icon type="caret-right" v-if="!item.expanded" />
                                            <a-icon type="caret-down" v-else />
                                        </div>
                                        <p class="name" :title="item.areaName">{{item.areaName}}</p>
                                        <span class="score">({{item.count}})</span>
                                    </div>
                                    <template v-if="item.child && item.child.length">
                                        <div class="sub-list" v-show="item.expanded">
                                            <div class="sub-item" v-for="sub in item.child" :key="sub.deptId" :class="{active:searchData.idPoc && searchData.idPoc == sub.deptId}" @click="onClickChildDept(sub)">
                                                <div class="sub-item-wrap">
                                                    <p class="sub-item-name" :title="sub.deptName">{{sub.deptName}}</p>
                                                    <span class="sub-item-score">({{sub.count}})</span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slide-list" v-if="showScreeing == 'datadomain'">
                        <ul id="datadomain-list" class="diy-scroll">
                            <li @click="handleDataDominClick('')" :class="[searchData.datadomain == '' ? 'active' : '', 'all']">
                                <div class="flex-box">
                                    <span class="title">全部领域</span>
                                    <span>({{ countInit(dataDomainData) }})</span>
                                </div>
                            </li>
                            <li @click="handleDataDominClick(item.dataDomain)" v-for="item in dataDomainData.slice(0, 8)" :key="item.datadomain" :class="[searchData.datadomain == item.dataDomain ? 'active' : '']">
                                <div class="flex-box">
                                    <span class="title" v-if="item.dataDomain">{{ item.dataDomainName || getDeepLabel(item.dataDomain, dataDomainOptions) || item.dataDomain }}</span>
                                    <span>({{ item.count }})</span>
                                </div>
                            </li>
                            <template v-if="hasShowDataDomainAll">
                                <li @click="handleDataDominClick(item.dataDomain)" v-for="item in dataDomainData.slice(8)" :key="item.dataDomain + '1'" :class="[searchData.idPoc == item.dataDomain ? 'active' : '']">
                                    <div class="flex-box">
                                        <span class="title" v-if="item.dataDomain">{{ item.dataDomainName || getDeepLabel(item.dataDomain, dataDomainOptions) || item.dataDomain }}</span>
                                        <span>({{ item.count }})</span>
                                    </div>
                                </li>
                            </template>
                        </ul>
                        <div v-if="dataDomainData.length > 8" @click="hasShowDataDomainAll = !hasShowDataDomainAll" style="cursor:pointer">{{ hasShowDataDomainAll ? '收起' : '展开' }}隐藏项</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 搜索框 -->
        <div class="right-content">
            <main class="main">
                <div class="main-header">
                    <a-input-search class="search-input" placeholder="搜索" v-model.trim="searchData.keywords" style="width: 200px" @search="onSearch" />
                </div>
                <div class="main-result-tips">
                    <p class="main-result-tips__title">
                        为你找到
                        <span>{{ pageOptions.total }}</span>
                        个数据集
                    </p>
                    <div class="main-result-tips__opts">
                        <div class="btn-groups">
                            <span class="btn-capacity" :class="{active:searchData.capacity=='desc'}" @click="onClickBtnCapacity">数据容量<a-icon type="arrow-down" /></span>
                        </div>
                        
                        <div class="clear pointer" @click="clearConditions">清空条件</div>
                    </div>
                    
                </div>

                <a-spin :spinning="loading" tip="数据加载中...">
                    <template v-if="dataList.length">
                        <div class="main-list">
                            <StarList @titleClick="handleDetailClick" :list="dataList" :starList="starList" @starClick="starClick" @loadHandle="loadHandle"></StarList>
                            <div class="pagination">
                                <a-pagination :pageSizeOptions="['10', '20', '50']" show-size-changer show-quick-jumper :defaultPageSize="pageOptions.pageSize" :current="pageOptions.pageNo" :total="pageOptions.total" @showSizeChange="handlePageSizeChange" @change="handlePageChange" />
                            </div>
                        </div>
                    </template>
                    <no-data v-else></no-data>
                </a-spin>
            </main>
        </div>
    </div>
</template>

<script>
import NoData from '@/components/NoData.vue'
import StarList from '@/components/StarList.vue'
import TabBar from '../components/TabBar'
import LoginModal from '@/components/LoginModal'
import { getDeptTreeList, GetList, GetStarList, GiveStar, DeleteStar, GetOrg, GetDataDomain } from '../api'
import { getLoadTable } from '../api/downLoad'
import { getCookie } from '@/utils/cookie'
import { getDeepLabel, formatTime, convertRes2Blob } from '@/utils/tools'
import { mapState } from 'vuex'

const defaultPageOptions = {
    pageNo: 1, //页码
    pageSize: 10, //每页条数
    total: 0
} //为了清空条件
export default {
    name: 'InterfaceList',
    components: {
        NoData,
        StarList,
        TabBar,
        LoginModal
    },
    data() {
        return {
            hasShowDataDomainAll: false,
            hasShowOrgAll: false,
            loading: true, //加载中
            pageOptions: { ...defaultPageOptions },
            //筛选内容数组
            typeTabs: [
                { value: '0', label: '数据来源单位', active: true },
                { value: '1', label: '数据领域', active: false }
            ],
            total: 0,
            dataList: [], //数组列表
            starList: [], //收藏列表
            orgData: [], //数据来源单位数据
            dataDomainData: [], //数据领域数据
            showScreeing: 'org',
            orgHide: true,
            datadomainHide: true,
            searchData: {
                datadomain: '',
                idPoc: null,
                keywords: '',
                areaCode: null,
                capacity: null,
            },
            // 用户数据
            KF_USER: getCookie('KF_USER') ? JSON.parse(getCookie('KF_USER')) : {},
            openModal: false,
            deptTreeList:[],
        }
    },
    computed: {
        ...mapState(['dataDomainOptions'])
    },
    watch:{
        '$store.state.areaCode':{
            handler:function(val){
                console.log('val');
                this.initDept()
                this.filterListByDept()
            }
        }
    },
    methods: {
        getDeepLabel,
        formatTime,
        starClick(value, state) {
            if (getCookie('kf_token')) {
                if (state) {
                    DeleteStar({ id: value.id, resourceType: 1 }).then(() => {
                        this.getStar()
                        return
                    })
                } else {
                    GiveStar({
                        publishTime: value.releaseDate ? formatTime(value.releaseDate) + ' ' + formatTime(value.releaseDate, true) : '',
                        resourceAbstract: value.resSummary,
                        resourceId: value.id,
                        resourceName: value.resName,
                        resourceProviderId: value.idPoc,
                        resourceProviderName: value.idPocName,
                        resourceUpdateTime: value.updateDate ? formatTime(value.updateDate) + ' ' + formatTime(value.updateDate, true) : '',
                        resourceType: 1
                    }).then(res => {
                        this.getStar()
                        return
                    })
                }
            } else {
                this.openModal = true
            }
        },
        changeOpenModal(val) {
            this.openModal = val
        },
        onSearch(value) {
            this.pageOptions.pageNo = 1 //切换页数
            this.getData()
        },
        // 数据领域点击切换搜索事件
        handleDataDominClick(dataDomain) {
            this.pageOptions.pageNo = 1
            this.searchData.datadomain = dataDomain
            this.searchData.idPoc = null
            this.searchData.areaCode = null
            this.getData()
        },
        // 组织机构点击切换搜索事件
        handleOrgClick(idPoc) {
            this.pageOptions.pageNo = 1
            this.searchData.idPoc = idPoc
            this.getData()
        },
        switchTypeTab(value) {
            //点击筛选切换
            this.pageOptions.pageNo = 1
            this.searchData.datadomain = ''
            this.searchData.idPoc = null
            this.searchData.areaCode = null
            this.deptTreeList.map(item=>{
                item.expanded = false
                return item
            })
            this.getData()
            this.showScreeing = value == 0 ? 'org' : 'datadomain'
            if (this._.find(this.typeTabs, { active: true }).value == value) return
            this.typeTabs = this.typeTabs.map(item => {
                item.active = item.value == value ? true : false
                return item
            })
        },
        // 跳转详情事件
        handleDetailClick(item) {
            this.$router.push({
                name: 'DataSetDetail',
                query: {
                    id: item.id,
                    isStructuredData: 1
                }
            })
        },
        handlePageChange(current, size) {
            //分页页码切换触发事件
            this.pageOptions.pageNo = current //切换页数
            this.pageOptions.pageSize = size
            this.getData()
        },
        handlePageSizeChange(current, size) {
            this.pageOptions.pageNo = 1 //切换页数
            this.pageOptions.pageSize = size
            this.getData()
        },
        // 总数初始化
        countInit(data) {
            let count = 0
            data.forEach(item => {
                count += item.count
            })
            return count
        },
        // 获取列表数据
        async getData() {
            let params = {
                pageNo: this.pageOptions.pageNo,
                pageSize: this.pageOptions.pageSize,
                param: {
                    resName: this.searchData.keywords,
                    ...this.searchData
                }
            }
            this.loading = true
            await GetList(params)
                .then(res => {
                    this.dataList = res.records
                    console.log(res.records)
                    this.pageOptions.total = res.total
                    this.loading = false
                })
                .catch(err => {
                    console.log(err)
                    this.$message.error('数据获取失败')
                    this.loading = false
                })
        },
        async getStar() {
            // 获取收列表
            await GetStarList({ id: this.KF_USER.id, isstructuredData: 1 }).then(res => {
                this.starList = res
                // console.log(res)
            })
        },
        async getJson() {
            // 获取左侧数据来源单位和数据领域数据
            // await GetOrg({ type: '1', isStructuredData: '1' }).then(res => {
            //     this.orgData = res
            // })
            await GetDataDomain({ type: '1', isStructuredData: '1' }).then(res => {
                this.dataDomainData = res
            })
            // 当store中没有数据时获取组织机构，数据领域数据
            // if (this.orgOptions.length == 0) {
            //     this.$store.dispatch('getData')
            // }
            this.hideInit()
        },
        clearConditions() {
            //清空条件
            this.searchData = {
                datadomain: '',
                idPoc: null,
                areaCode:null,
                keywords: '',
                capacity: null,
            }
            this.pageOptions = { ...defaultPageOptions }
            this.getData()
        },
        hideInit() {
            // 获取左侧列表的高度设置是否隐藏
            let orgHeight = document.getElementById('org-list').getBoundingClientRect().height
            let datadomainHeight = document.getElementById('org-list').getBoundingClientRect().height
            if (orgHeight > 240) {
                this.orgHide = true
            } else {
                this.orgHide = false
            }
            if (datadomainHeight > 240) {
                this.datadomainHide = true
            } else {
                this.datadomainHide = true
            }
        },
        // 下载
        loadHandle(index, item) {
            if (!getCookie('kf_token')) {
                this.openModal = true
                return
            }
            if (!item.dataBaseName) {
                this.$message.warn('暂无数据，无法下载')
                return
            }
            getLoadTable({
                id: item.id,
                type: index + 1
            }).then(response => {
                // debugger
                console.log(response)
                convertRes2Blob(response)
            })
        },
        getDeptTreeList(){
            return new Promise((resolve,reject)=>{
                getDeptTreeList({isStructuredData:1}).then(res=>{
                    if(res){
                        this.deptTreeList = res.map(item=>{
                            item.expanded = false
                            return item
                        })
                        console.log('deptTreeList=>',this.deptTreeList);
                        resolve()
                    }
                    reject()
                }).catch(()=>{
                    reject()
                })
            })
            
        },
        onClickParentDept(row){
            // 判断activeDeptRow的areaCode是否和row.areaCode相等，相等的话直接return
            row.expanded = !row.expanded
            this.searchData.areaCode = row.areaCode
            this.searchData.idPoc = row.deptId
            this.filterListByDept()
        },
        onClickChildDept(row){
            this.searchData.areaCode = null
            this.searchData.idPoc = row.deptId
            this.filterListByDept()
        },
        filterListByDept(){
            this.pageOptions.pageNo = 1
            this.searchData.datadomain = ''
            this.getData()
        },
        initDept(){
            this.searchData.areaCode = this.$store.state.areaCode
            this.searchData.idPoc = null
            this.deptTreeList.map(item=>{
                if(item.areaCode == this.searchData.areaCode){
                    item.expanded = true
                }
                return item
            })
        },
        onClickBtnCapacity(){
            this.searchData.capacity = !this.searchData.capacity ? 'desc' : ''
            this.getData()
        }
    },
    async activated() {
        await this.getDeptTreeList()
        this.initDept()
        this.getJson()
        this.getStar()
        this.getData()
    }
}
</script>

<style scoped lang="scss">
.center-content {
    padding-top: 24px;
    padding-bottom: 48px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    .left-content {
        flex-shrink: 0;
        width: 265px;
        .main {
            .main-header {
                height: 64px;
                display: flex;
                flex-flow: row nowrap;
                border-bottom: 1px solid #e7e7e7;
            }
            .order-tab {
                align-self: flex-end;
            }
        }
        .left-list {
            .slide-list.height-auto {
                height: auto;
            }
            .slide-list.height-hide {
                overflow: hidden;
                height: 240px;
            }
            .slide-list {
                margin-top: 10px;
                ul {
                    max-height: 480px;
                    overflow: auto;
                }
                .title {
                    margin-left: 10px;
                    color: #333;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-right: 32px;
                }
                .flex-box {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                }
                li {
                    display: flex;
                    padding: 8px 27px 8px 12px;
                    align-items: center;
                    height: 30px;
                    font-size: $fs12;
                    cursor: pointer;
                    span {
                        color: #999;
                        font-size: $fs12;
                    }
                }
                li:hover {
                    background-color: #357df1;
                    span {
                        color: #fff;
                    }
                }
                li.active {
                    background-color: #357df1;
                    span {
                        color: #fff;
                    }
                }
            }
            .dept-tree-list{
                margin-top: 10px;
                background: #f2f5f9;
                .dept-tree-item{
                    border-bottom: 1px solid #d5d5d5;
                    font-size: 12px;
                    .dept-tree-item-wrap{
                        .dept-tree-name{
                            height: 50px;
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            padding: 0 10px 0 24px;
                            position: relative;
                            .icon-caret{
                                position: absolute;
                                left: 8px;
                                top: 50%;
                                transform: translateY(-50%);
                                color: #999;
                            }
                            .name{
                                font-weight: bold;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                            .score{
                                margin-left: auto;
                                flex-shrink: 0;
                                color: #999;
                            }
                            &.active{
                                background: #357df1;
                                color: #fff;
                                .score,
                                .icon-caret{
                                    color: #fff;
                                }
                            }
                        }
                        .sub-list{
                            max-height: 690px;
                            overflow-y: auto;
                            .sub-item{
                                padding: 0 10px 0 24px;
                                .sub-item-wrap{
                                    height: 40px;
                                    display: flex;
                                    align-items: center;
                                    .sub-item-name{
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }
                                    .sub-item-score{
                                        margin-left: auto;
                                        flex-shrink: 0;
                                        color: #999;
                                    }
                                }
                                &.active{
                                    background: #357df1;
                                    color: #fff;
                                    .sub-item-wrap{
                                        .sub-item-score{
                                            color: #fff;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .right-content {
        /* flex: 1; */
        margin-left: 20px;
        /* padding: 0 32px 0 52px; */
        background: #fff;
        min-height: 800px;
        width: 900px;
        .main {
            .main-header {
                height: 64px;
                display: flex;
                flex-flow: row nowrap;
                border-bottom: 1px solid #e7e7e7;
                .order-tab {
                    align-self: flex-end;
                }
                .search-input {
                    align-self: flex-end;
                    margin-left: auto;
                    /deep/.ant-input {
                        background-color: #f9f9f9;
                        position: relative;
                        bottom: -1px;
                    }
                }
            }
            .main-result-tips {
                position: relative;
                margin-top: 28px;
                display: flex;
                align-items: center;
                .main-result-tips__title {
                    font-weight: bold;
                    font-size: $fs20;
                    color: #333;
                    span {
                        color: #357df1;
                    }
                }
                .main-result-tips__opts{
                    margin-left: auto;
                    display: flex;
                    align-items: center;
                    .btn-groups{
                        margin-right: 12px;
                        font-size: $fs12;
                        .btn-capacity{
                            cursor: pointer;
                            &.active{
                                color: #357df1;
                            }
                        }
                    }
                }
                .clear {
                    padding: 12px 0;
                    font-size: $fs12;
                    font-weight: bold;
                }
            }
        }
        .pagination {
            text-align: center;
            margin-top: 40px;
        }
    }
}
</style>
