<template>
  <div >
      <div class="chart-wrap">
         <main id="my-chart">
         </main>

      </div>

  </div>
</template>

<script>
import echarts from "echarts";
export default {
     props: {
        chartsData: {
            type: Object,
            required:true
        }
    },
    data() {
        return {

        }
    },
    watch: {
         chartsData: {
           handler(newValue, oldValue) {
             this. chartsData = newValue;  //把新值赋值给我们的属性数据
              this.drawLine() //刷新echarts图表
           },
           deep: true
            }
         },
    created () {},
    mounted() {
        console.log(this.chartsData);
        
        this.$nextTick(() =>{
            this.drawLine()
        })
        
    },
    methods: {
        drawLine () {
            var myChart = echarts.init(document.getElementById('my-chart'))
             myChart.setOption( {
                   color: ['#3398DB'],
                 title: {
                         text: '分析结果',
                         subtext: '单位个',
                         left: 'left'
                     },
                tooltip: {
                        trigger: 'axis'
                        },
                        xAxis: {
                            axisLabel: {
                             interval:0, //坐标刻度之间的显示间隔，默认就可以了（默认是不重叠）
                              rotate:38,//调整数值改变倾斜的幅度（范围-90到90）
                               formatter: function(value) {
                            var res = value // 长度超过6个的以省略号显示
                            if (res.length > 6) {
                                res = res.substring(0, 7) + '..'
                            }
                            return res
                        }  
                            },
                            type: 'category',
                            boundaryGap: false,
                              data: this.chartsData.xData
                        },
                        yAxis: {
                            type: 'value'
                        },
                        series: [{
                             data: this.chartsData.yData,
                            type: 'line',
                            // smooth: true,
                            areaStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: '#357df1'
                                }, {
                                    offset: 1,
                                    color: '#fff'
                                      }])
                                  }
                         }]
              }

              )
         }
    }

}
</script>

<style scoped lang="scss" >
.chart-wrap{
    width: 780px;
    height: 538px;
     border: 1px solid #E5E5E5;
     margin-left: 10px;
     main {
        width: 748px;
        height: 538px;
     }
}

</style>