<template>
    <div>
        <a-modal width="1000px" :title="title" @cancel="handleModalCancel" :visible="visible" okText="提交" @ok="handleConfirm" class="cla-modal">
            <div class="main">
                <p>
                    <SameStyleBox title="申请信息"></SameStyleBox>
                </p>
                <a-form class="form-wrap" :hideRequiredMark="true">
                    <a-row>
                        <a-col :span="12">
                            <a-form-item label="申请用户名称" class="form-item">
                                <a-input
                                    placeholder="请输入申请用户名称"
                                    v-decorator="[
                                        'username',
                                        {
                                            rules: [{ required: true, message: '申请用户名称不能为空！' }]
                                        }
                                    ]"
                                />
                            </a-form-item>
                            <a-form-item label="申请用户邮箱" class="form-item">
                                <a-input
                                    placeholder="请输入申请用户邮箱"
                                    v-decorator="[
                                        'email',
                                        {
                                            rules: [
                                                {
                                                    type: 'email',
                                                    message: '邮箱格式不正确'
                                                },
                                                { required: true, message: '申请用户邮箱不能为空！' }
                                            ]
                                        }
                                    ]"
                                />
                            </a-form-item>
                            <a-form-item label="数源部门" class="form-item">
                                <a-input readonly defaultValue="舟山市大数据局" />
                            </a-form-item>
                            <a-form-item label="目录名称" class="form-item">
                                <a-input readonly defaultValue="信息资源名称" />
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item label="申请用户电话" class="form-item">
                                <a-input
                                    placeholder="请输入申请用户电话"
                                    v-decorator="[
                                        'phone',
                                        {
                                            rules: [
                                                {
                                                    pattern: /^1[0-9]{10}$/,
                                                    message: '请输入正确的手机号'
                                                },
                                                { required: true, message: '申请用户电话不能为空！' }
                                            ]
                                        }
                                    ]"
                                />
                            </a-form-item>
                            <a-form-item label="应用场景" class="form-item">
                                <a-textarea placeholder="非必填" class="form-item-situation" :rows="4" />
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="12"></a-col>
                        <a-col :span="12"></a-col>
                    </a-row>
                </a-form>
            </div>
            <a-icon slot="closeIcon" type="close-circle" />
        </a-modal>
    </div>
</template>

<script>
import { formatTime, getLable } from '@/utils/tools'
export default {
    props: {
        title: {
            type: String,
            default: '目录信息'
        },
        data: {
            type: Object,
            default: () => {
                return {}
            }
        },
        visible: {
            type: Boolean
        }
    },
    data() {
        return {
            form: this.$form.createForm(this, { name: 'catalogLimitedApplyForm' }),
            labelOptions: {
                dataTypeOptions: [],
                exchangeAttrOptions: [],
                isOptions: [
                    { label: '否', value: '0' },
                    { label: '是', value: '1' }
                ]
            }
        }
    },
    methods: {
        formatTime,
        getLable,
        handleModalCancel() {
            this.$emit('modalCancel')
        },
        handleConfirm() {
            this.$emit('confirm')
        }
    },
    watch: {},
    mounted() {}
}
</script>

<style lang="scss" scoped>
::v-deep .ant-modal-title {
    font-size: 30px;
    height: 50px;
    line-height: 50px;
    color: #000025;
}
::v-deep .ant-modal-close {
    padding: 16px 40px 16px 0;
    line-height: 50px;
    height: 50px;
    color: #dbdbdb;
}
::v-deep .ant-modal-close-x {
    font-size: 20px;
}
.cla-modal {
    .form-item {
        display: flex;
        flex-flow: row nowrap;
        padding: 0 34px;
        ::v-deep .ant-form-item-label {
            flex-shrink: 0;
            min-width: 110px;
            padding-right: 12px;
            /* text-align: left; */
        }
        ::v-deep .ant-form-item-control-wrapper {
            flex: 1;
        }
        ::v-deep .ant-input::not(.form-item-situation) {
            flex: 1;
            height: 40px;
            line-height: 40px;
        }
        ::v-deep .ant-input.form-item-situation {
            resize: none;
        }
        ::v-deep.ant-input[readonly='readonly'] {
            border: none;
            box-shadow: none;
            outline: 0;
        }
    }
}
</style>
