<template>
    <div class="center-content">
        <login-modal v-if="openModal" @cancel="openModal = false"></login-modal>
        <DataCatalogDetail :visible="visible" :data="detailData" @modalCancel="handleModalCancel"></DataCatalogDetail>
        <div class="left-content">
            <!-- 左侧筛选 -->
            <div class="main">
                <div class="main-header">
                    <TabBar :list="typeTabs" @switchTab="switchTypeTab" class="order-tab"></TabBar>
                </div>
                <div class="left-list">
                    <!-- <div class="slide-list diy-scroll" v-if="showScreeing == '0'">
                        <ul>
                            <li @click="handleOrgClick('')" :class="[searchData.idPoc == '' ? 'active' : '', 'all']">
                                <div class="flex-box">
                                    <span class="title">全部单位</span>
                                    <span>({{ countInit(catalogDeptsList) }})</span>
                                </div>
                            </li>
                            <li @click="handleOrgClick(item.deptId)" v-for="item in catalogDeptsList.slice(0, 8)" :key="item.deptId" :class="[searchData.idPoc == item.deptId ? 'active' : '']">
                                <div class="flex-box">
                                    <span class="title" v-if="item.deptId">{{ item.deptName || getDeepLabel(item.deptId) || item.deptId }}</span>
                                    <span>({{ item.count }})</span>
                                </div>
                            </li>
                            <template v-if="hasShowOrgAll">
                                <li @click="handleOrgClick(item.deptId)" v-for="item in catalogDeptsList.slice(8)" :key="item.deptId + '1'" :class="[searchData.idPoc == item.deptId ? 'active' : '']">
                                    <div class="flex-box">
                                        <span class="title" v-if="item.deptId">{{ item.deptName || getDeepLabel(item.deptId) || item.deptId }}</span>
                                        <span>({{ item.count }})</span>
                                    </div>
                                </li>
                            </template>
                        </ul>
                        <div v-if="catalogDeptsList.length > 8" @click="hasShowOrgAll = !hasShowOrgAll" style="cursor:pointer">{{ hasShowOrgAll ? '收起' : '展开' }}隐藏项</div>
                    </div> -->
                    <div v-if="showScreeing == '0'">
                        <div class="dept-tree-list">
                            <div class="dept-tree-item" v-for="(item,index) in deptTreeList" :key="index">
                                <div class="dept-tree-item-wrap">
                                    <div class="dept-tree-name" :class="{active:!searchData.idPoc && searchData.areaCode == item.areaCode}" @click.stop="onClickParentDept(item)">
                                        <div v-if="item.child && item.child.length" class="icon-caret">
                                            <a-icon type="caret-right" v-if="!item.expanded" />
                                            <a-icon type="caret-down" v-else />
                                        </div>
                                        <p class="name" :title="item.areaName">{{item.areaName}}</p>
                                        <span class="score">({{item.count}})</span>
                                    </div>
                                    <template v-if="item.child && item.child.length">
                                        <div class="sub-list" v-show="item.expanded">
                                            <div class="sub-item" v-for="sub in item.child" :key="sub.deptId" :class="{active:searchData.idPoc && searchData.idPoc == sub.deptId}" @click="onClickChildDept(sub)">
                                                <div class="sub-item-wrap">
                                                    <p class="sub-item-name" :title="sub.deptName">{{sub.deptName}}</p>
                                                    <span class="sub-item-score">({{sub.count}})</span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slide-list diy-scroll" v-if="showScreeing == '1'">
                        <ul>
                            <li @click="handleDataDominClick('')" :class="[searchData.datadomain == '' ? 'active' : '', 'all']">
                                <div class="flex-box">
                                    <span class="title">全部领域</span>
                                    <span>({{ countInit(catalogDomainList) }})</span>
                                </div>
                            </li>
                            <li @click="handleDataDominClick(item.dataDomain)" v-for="item in catalogDomainList.slice(0, 8)" :key="item.datadomain" :class="[searchData.datadomain == item.dataDomain ? 'active' : '']">
                                <div class="flex-box">
                                    <span class="title" v-if="item.dataDomain && dataDomainOptions.length">{{ item.dataDomainName || getDeepLabel(item.dataDomain, dataDomainOptions) || item.dataDomain }}</span>
                                    <span>({{ item.count }})</span>
                                </div>
                            </li>
                            <template v-if="hasShowDataDomainAll">
                                <li @click="handleDataDominClick(item.dataDomain)" v-for="item in catalogDomainList.slice(8)" :key="item.dataDomain + '1'" :class="[searchData.datadomain == item.dataDomain ? 'active' : '']">
                                    <div class="flex-box">
                                        <span class="title" v-if="item.dataDomain">{{ item.dataDomainName || getDeepLabel(item.dataDomain, dataDomainOptions) || item.dataDomain }}</span>
                                        <span>({{ item.count }})</span>
                                    </div>
                                </li>
                            </template>
                        </ul>
                        <div v-if="catalogDomainList.length > 8" @click="hasShowDataDomainAll = !hasShowDataDomainAll" style="cursor:pointer">{{ hasShowDataDomainAll ? '收起' : '展开' }}隐藏项</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="right-content">
            <main class="main">
                <div class="main-header">
                    <a-input-search class="search-input" placeholder="搜索" v-model.trim="searchData.resName" style="width: 200px" @search="onSearch" />
                    <div class="btn-download" @click="handleDownload" style="margin-left: auto;">开放目录下载</div>
                </div>

                <a-spin :spinning="loading" tip="数据加载中...">
                    <div class="main-result-tips">
                        <p>
                            为你找到
                            <span>{{ pageOptions.total }}</span>
                            个数据资源目录
                        </p>
                        <div class="clear pointer" @click="clearConditions">清空条件</div>
                    </div>
                    <template v-if="dataList.length">
                        <div class="main-list">
                            <StarList @titleClick="handleDetailClick" :list="dataList" :starList="starList" @starClick="starClick" :showDownLoad="false">
                                <template v-slot:rightBtn="{ item }">
                                    <template>
                                        <div class="btn-add" :class="item.hasApplied ? 'btn-added' : ''" @click="handleAppyBtnClick(item)" v-if="item.isopen != '1'">
                                            {{ item.hasApplied ? '已申请' : '受限目录申请' }}
                                        </div>
                                    </template>
                                </template>
                            </StarList>
                            <div class="pagination">
                                <a-pagination :pageSizeOptions="['10', '20', '50']" show-size-changer show-quick-jumper :defaultPageSize="pageOptions.pageSize" :current="pageOptions.pageNo" :total="pageOptions.total" @showSizeChange="handlePageSizeChange" @change="handlePageChange" />
                            </div>
                        </div>
                    </template>
                    <no-data v-else></no-data>
                </a-spin>
            </main>
        </div>
        <CatalogLimitedApplyModel title="受限目录申请表" @modalCancel="handleCancelApplyCatalogLimited" @confirm="handleSubmitBtn" :visible="showApplyCatalogModel"></CatalogLimitedApplyModel>
    </div>
</template>

<script>
import NoData from '@/components/NoData.vue'
import StarList from '@/components/StarList.vue'
import TabBar from '../components/TabBar'
import LoginModal from '@/components/LoginModal'
import DataCatalogDetail from '@/components/DataCatalogDetail'
import CatalogLimitedApplyModel from '@/components/CatalogLimitedApplyModel'
import { getDeptTreeList, GetDataCatalogList, GetStarList, GiveStar, DeleteStar, GetDetail, GetDicts, getAppliedCatalogs, getRelateAppList } from '../api'

import { getCookie } from '@/utils/cookie'
import { getDeepLabel, formatTime, getLable } from '@/utils/tools'
import { mapState } from 'vuex'
const defaultPageOptions = {
    pageNo: 1, //页码
    pageSize: 10, //每页条数
    total: 0
} //为了清空条件
export default {
    name: 'DataCatalogList',
    components: {
        NoData,
        StarList,
        TabBar,
        LoginModal,
        DataCatalogDetail,
        CatalogLimitedApplyModel
    },
    created() {
        if (this.$route.query.type) {
            this.showScreeing = this.$route.query.type
            if (this.showScreeing) {
                this.typeTabs = this.typeTabs.map(item => {
                    item.active = item.value == this.showScreeing
                    return item
                })
            }
            this.activeLeftItemId = this.$route.query.typeId
        }
        this.getRelateAppList()
    },
    async mounted() {
        this.$store.dispatch('getData')
        this.$store.dispatch('getDataCatalogTypeList')
        await this.getDeptTreeList()
        this.initDept()
        if (this.$route.query.typeId) {
            if (!Number(this.$route.query.type)) {
                this.searchData.idPoc = this.$route.query.typeId
                this.searchData.areaCode = this.$route.query.areaCode
            } else {
                this.searchData.datadomain = this.$route.query.typeId
            }
        }
        this.expandAreaCode()
        this.getJson()
        this.getStar()
        // this.getInit()
    },
    data() {
        return {
            hasShowDataDomainAll: false,
            hasShowOrgAll: false,
            showApplyCatalogModel: false,
            visible: false, //详情弹框状态
            loading: true, //加载中
            pageOptions: { ...defaultPageOptions },
            //筛选内容数组
            typeTabs: [
                { value: '0', label: '数据来源单位', active: true },
                { value: '1', label: '数据领域', active: false }
            ],
            total: 0,
            dataList: [], //数组列表
            starList: [], //收藏列表
            // 当前展示位数据来源单位还是数据领域
            showScreeing: 0,
            // 两个展出更多
            orgHide: true,
            datadomainHide: true,
            // 抖索内容
            searchData: {
                datadomain: '', //左侧数据领域
                idPoc: null, //左侧数据来源单位
                resName: '', //输入框搜索
                areaCode: null,
            },
            // 用户数据
            KF_USER: getCookie('KF_USER') ? JSON.parse(getCookie('KF_USER')) : {},
            // 详情数据
            detailData: {},
            // 字典集合
            labelOptions: {
                frequeryOptions: [],
                resFormatOptions: [],
                dataTypeOptions: [],
                exchangeAttrOptions: [],
                shareAttrOptions: [],
                isOptions: [
                    { label: '否', value: '0' },
                    { label: '是', value: '1' }
                ]
            },
            openModal: false,
            resClassName: '',
            dataDomainName: '',
            hasAppliedList: [],
            relateAppList: [], //我的关联应用列表
            deptTreeList:[],
        }
    },
    computed: {
        ...mapState(['dataDomainOptions', 'catalogDeptsList', 'catalogDomainList'])
    },
    watch:{
        '$store.state.areaCode':{
            handler:function(val){
                console.log('val');
                this.initDept()
                this.filterListByDept()
            }
        }
    },
    methods: {
        getDeepLabel,
        formatTime,
        getLable,
        getRelateAppList() {
            let params = {
                param: {
                    applicationState: 3
                }
            }
            getRelateAppList(params)
                .then(res => {
                    // console.log('getRelateAppList=>', res)
                    this.relateAppList = res.records || []
                })
                .catch(err => {
                    console.log(err)
                })
        },
        showGoAddAppConfirm() {
            let _this = this
            this.$confirm({
                class: 'goAddAppConfirm',
                title: '您当前没有审核通过的应用，不可进行受限目录申请。',
                closable: true,
                cancelText: '确定',
                cancelType: 'primary',
                okText: '前往应用注册',
                okType: 'link',
                maskClosable: true,
                onOk() {
                    _this.$router.push({
                        name: 'MyAppList'
                    })
                },
                onCancel() {}
            })
        },
        handleAppyBtnClick(item) {
            //受限目录申请
            // this.showApplyCatalogModel = true
            if (!getCookie('kf_token')) {
                this.openModal = true
                return
            }
            if (item.hasApplied) return
            if (!this.relateAppList.length) {
                //已登录，未申请接口，但是该账号未关联应用，弹出提示去关联应用提示框随后跳到新增关联应用页面
                this.showGoAddAppConfirm()
                return
            }
            this.$router.push({ path: 'Application', query: { id: item.id } })
            console.log(item)
        },
        handleSubmitBtn() {
            this.showApplyCatalogModel = false
        },
        handleCancelApplyCatalogLimited() {
            this.showApplyCatalogModel = false
        },
        starClick(value, state) {
            if (getCookie('kf_token')) {
                if (state) {
                    DeleteStar({ id: value.id, resourceType: 0 }).then(() => {
                        this.getStar()
                        return
                    })
                } else {
                    GiveStar({
                        publishTime: value.releaseDate ? formatTime(value.releaseDate) + ' ' + formatTime(value.releaseDate, true) : '',
                        resourceAbstract: value.resSummary,
                        resourceId: value.id,
                        resourceName: value.resName,
                        resourceProviderId: value.idPoc,
                        resourceProviderName: value.idPocName,
                        resourceUpdateTime: value.updateDate ? formatTime(value.updateDate) + ' ' + formatTime(value.updateDate, true) : ''
                    }).then(() => {
                        this.getStar()
                        return
                    })
                }
            } else {
                this.openModal = true
            }
        },
        changeOpenModal(val) {
            this.openModal = val
        },
        onSearch(value) {
            this.pageOptions.pageNo = 1 //切换页数
            this.getInit()
        },
        // 数据领域点击切换搜索事件
        handleDataDominClick(dataDomain) {
            this.pageOptions.pageNo = 1
            this.searchData.idPoc = null
            this.searchData.areaCode = null
            this.searchData.datadomain = dataDomain
            this.getInit()
        },
        // 组织机构点击切换搜索事件
        handleOrgClick(idPoc) {
            this.pageOptions.pageNo = 1
            this.searchData.datadomain = ''
            this.searchData.idPoc = idPoc
            this.getInit()
        },
        switchTypeTab(value) {
            //点击筛选切换
            this.pageOptions.pageNo = 1
            this.searchData.datadomain = ''
            this.searchData.idPoc = null
            this.searchData.areaCode = null
            this.deptTreeList.map(item=>{
                item.expanded = false
                return item
            })
            // debugger
            this.getInit()
            this.showScreeing = value
            if (this._.find(this.typeTabs, { active: true }).value == value) return
            this.typeTabs = this.typeTabs.map(item => {
                item.active = item.value == value ? true : false
                return item
            })
        },
        // 跳转详情事件
        handleDetailClick(item) {
            GetDetail({ id: item.id }).then(res => {
                this.detailData = res
                this.visible = true
                this.getInit()
            })
        },
        // 弹窗关闭事件
        handleModalCancel() {
            this.visible = false
        },
        handlePageChange(current, size) {
            //分页页码切换触发事件
            this.pageOptions.pageNo = current //切换页数
            this.pageOptions.pageSize = size
            this.getInit()
        },
        handlePageSizeChange(current, size) {
            this.pageOptions.pageNo = 1 //切换页数
            this.pageOptions.pageSize = size
            this.getInit()
        },
        // 总数初始化
        countInit(data) {
            let count = 0
            // debugger
            if (data.length) {
                data.forEach(item => {
                    count += item.count
                })
            }

            return count
        },
        // 获取列表数据

        getInit() {
            // debugger
            let dataCatalogListParams = {
                pageNo: this.pageOptions.pageNo,
                pageSize: this.pageOptions.pageSize,
                param: {
                    ...this.searchData
                }
            }
            this.loading = true
            Promise.allSettled([getAppliedCatalogs(), GetDataCatalogList(dataCatalogListParams)])
                .then(res => {
                    // debugger
                    this.handleHasAppliedRes(res[0])
                    this.handleGetDataCatalogListRes(res[1])
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                })
        },
        handleHasAppliedRes(res) {
            if (res.status == 'rejected') {
                this.hasAppliedList = []
                return
            }
            // debugger
            this.hasAppliedList = res.value
        },
        handleGetDataCatalogListRes(res) {
            if (res.status == 'rejected') {
                this.dataList = []
                return
            }
            // debugger
            this.dataList = res.value.records
            this.pageOptions.total = res.value.total
            this.dataList = this.dataList.map(item => {
                item.hasApplied = this.hasAppliedList.includes(item.id)
                return this._.omit(item, ['downloadNum'])
            })
        },
        async getStar() {
            // 获取收藏列表
            await GetStarList({ id: this.KF_USER.id, isstructuredData: 0 }).then(res => {
                this.starList = res
            })
        },
        getJson() {
            let _this = this

            GetDicts({ types: 'resFormat,frequery,dataType,exchangeAttr,shareAttr' })
                .then(res => {
                    _this.labelOptions.frequeryOptions = res.frequery
                    _this.labelOptions.resFormatOptions = res.resFormat
                    _this.labelOptions.dataTypeOptions = res.dataType
                    _this.labelOptions.exchangeAttrOptions = res.exchangeAttr
                    _this.labelOptions.shareAttrOptions = res.shareAttr

                    _this.getInit()
                })
                .catch(() => {
                    _this.catalogDeptsList = []
                    _this.catalogDomainList = []
                    _this.getInit()
                })

            // 当store中没有数据时获取组织机构，数据领域数据
            // if (this.orgOptions.length == 0) {
            //     this.$store.dispatch('getData')
            // }
        },
        clearConditions() {
            //清空条件
            this.searchData = {
                datadomain: '',
                idPoc: null,
                areaCode:null,
                resName: ''
            }
            this.pageOptions = { ...defaultPageOptions }
            this.getInit()
        },
        handleDownload(){
            window.open(window.globalData.dataCatalogDownloadUrl,'_blank')
        },
        getDeptTreeList(){
            return new Promise((resolve,reject)=>{
                getDeptTreeList({isStructuredData:0}).then(res=>{
                    if(res){
                        this.deptTreeList = res.map(item=>{
                            item.expanded = false
                            return item
                        })
                        console.log('deptTreeList=>',this.deptTreeList);
                        resolve()
                    }
                    reject()
                }).catch(()=>{
                    reject()
                })
            })
            
        },
        onClickParentDept(row){
            // 判断activeDeptRow的areaCode是否和row.areaCode相等，相等的话直接return
            row.expanded = !row.expanded
            this.searchData.areaCode = row.areaCode
            this.searchData.idPoc = row.deptId
            this.filterListByDept()
        },
        onClickChildDept(row){
            this.searchData.areaCode = null
            this.searchData.idPoc = row.deptId
            this.filterListByDept()
        },
        filterListByDept(){
            this.pageOptions.pageNo = 1
            this.searchData.datadomain = ''
            this.getInit()
        },
        expandAreaCode(){
            this.deptTreeList.map(item=>{
                if(item.areaCode == this.searchData.areaCode){
                    item.expanded = true
                }
                return item
            })
        },
        initDept(){
            this.searchData.areaCode = this.$store.state.areaCode
            this.searchData.idPoc = null
            this.expandAreaCode()
        },
    }
}
</script>

<style scoped lang="scss">
.center-content {
    padding-top: 24px;
    padding-bottom: 48px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    .left-content {
        flex-shrink: 0;
        width: 265px;
        .main {
            .main-header {
                height: 64px;
                display: flex;
                flex-flow: row nowrap;
                border-bottom: 1px solid #e7e7e7;
            }
            .order-tab {
                align-self: flex-end;
            }
        }
        .left-list {
            #org-list {
                overflow: hidden;
                height: 240px;
            }
            .slide-list.height-auto {
                height: auto;
            }
            .slide-list.height-hide {
                overflow: hidden;
                height: 240px;
            }
            .slide-list {
                max-height: 480px;
                overflow: auto;
                margin-top: 10px;
                .title {
                    margin-left: 10px;
                    color: #333;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-right: 32px;
                }
                .flex-box {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                }
                li {
                    display: flex;
                    padding: 8px 27px 8px 12px;
                    align-items: center;
                    height: 30px;
                    font-size: $fs12;
                    cursor: pointer;
                    span {
                        color: #999;
                        font-size: $fs12;
                    }
                }
                li:hover {
                    background-color: #357df1;
                    span {
                        color: #fff;
                    }
                }
                li.active {
                    background-color: #357df1;
                    span {
                        color: #fff;
                    }
                }
            }
            .show-all {
                cursor: pointer;
            }
            .show-all.show {
                visibility: visible;
            }
            .show-all.hide {
                visibility: hidden;
            }
            .dept-tree-list{
                margin-top: 10px;
                background: #f2f5f9;
                .dept-tree-item{
                    border-bottom: 1px solid #d5d5d5;
                    font-size: 12px;
                    .dept-tree-item-wrap{
                        .dept-tree-name{
                            height: 50px;
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            padding: 0 10px 0 24px;
                            position: relative;
                            .icon-caret{
                                position: absolute;
                                left: 8px;
                                top: 50%;
                                transform: translateY(-50%);
                                color: #999;
                            }
                            .name{
                                font-weight: bold;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                            .score{
                                margin-left: auto;
                                flex-shrink: 0;
                                color: #999;
                            }
                            &.active{
                                background: #357df1;
                                color: #fff;
                                .score,
                                .icon-caret{
                                    color: #fff;
                                }
                            }
                        }
                        .sub-list{
                            max-height: 690px;
                            overflow-y: auto;
                            .sub-item{
                                padding: 0 10px 0 24px;
                                .sub-item-wrap{
                                    height: 40px;
                                    display: flex;
                                    align-items: center;
                                    .sub-item-name{
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }
                                    .sub-item-score{
                                        margin-left: auto;
                                        flex-shrink: 0;
                                        color: #999;
                                    }
                                }
                                &.active{
                                    background: #357df1;
                                    color: #fff;
                                    .sub-item-wrap{
                                        .sub-item-score{
                                            color: #fff;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .right-content {
        /* flex: 1; */
        margin-left: 20px;
        /* padding: 0 32px 0 52px; */
        background: #fff;
        min-height: 800px;
        width: 900px;
        .main {
            .main-header {
                height: 64px;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                border-bottom: 1px solid #e7e7e7;
                .order-tab {
                    align-self: flex-end;
                }
                .search-input {
                    /deep/.ant-input {
                        background-color: #f9f9f9;
                        position: relative;
                        bottom: -1px;
                    }
                }
                .btn-download{
                    padding: 8px 18px;
                    text-align: center;
                    border-radius: 4px;
                    font-size: 12px;
                    color: #fff;
                    background-color: #357df1;
                    transition: all 0.3s ease;
                    cursor: pointer;
                }
            }
            .main-result-tips {
                position: relative;
                margin-top: 28px;
                p {
                    font-weight: bold;
                    font-size: $fs20;
                    color: #333;
                    span {
                        color: #357df1;
                    }
                }
                .clear {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    padding: 12px 0;
                    font-size: $fs12;
                    font-weight: bold;
                }
            }
        }
        .pagination {
            text-align: center;
            margin-top: 40px;
        }
    }
}
</style>
<style lang="scss">
.goAddAppConfirm {
    position: relative;
    /deep/ .ant-modal-body {
        padding: 60px 80px 24px !important;
    }
    /deep/ .ant-modal-confirm-btns {
        .ant-btn {
            &:first-of-type {
                background: #357df1;
                color: #fff;
                border: 1px solid #357df1;
            }
            &:last-of-type {
                color: #357df1;
                background: transparent;
            }
        }
    }
}
</style>
