<template>
    <div class="container">
        <div class="wrap">
            <header>
                <div class="title">{{visData.name}}</div>
                <div class="desc">{{visData.summary}}</div>
                <div class="data-item-wrap">
                    <div class="data-item">数据提供单位：{{visData.pocName}}</div>
                    <div class="data-item">最后更新时间：{{formatTime(visData.updateDate)}}</div>
                    <div class="visual-btn">
                        <span @click="handleFormShow" style="cursor: pointer">数据可视化</span>
                        <span style="cursor: pointer" v-if="goBack" @click="goFromShow">返回</span>
                    </div>
                </div>
            </header>

            <main>
                <!-- 列表展示-->
                <div class="data-visual">
                    <a-table
                        :columns="columns"
                        :scroll="{ x: 1500 }"
                        :data-source="tableData"
                        bordered
                        :rowKey="(record,index)=>{return index}"
                        v-if="isShowVisibleForm"
                        :pagination="false"
                    >
                        <!-- <a-tooltip>
                            <template slot="title">
                                <span slot-scope="value">{{ value }}</span>
                            </template>
                        </a-tooltip> -->
                    </a-table>

                    <!-- 饼状图 -->
                    <charts-pie v-else-if="chartType == 'pie'" :chartsData="chartsData"></charts-pie>
                    <!-- 折线图 -->
                    <ChartsLine v-else-if="chartType == 'line'" :chartsData="chartsData"></ChartsLine>
                    <!-- 柱状图 -->
                    <ChartsPillar v-else :chartsData="chartsData"></ChartsPillar>
                </div>

                <!-- 切换图表类型 抽屉效果 -->
                <div class="chartFrom">
                    <a-drawer
                        placement="right"
                        :closable="false"
                        :visible="formVisible"
                        :get-container="false"
                        :width="420"
                        :wrap-style="{ position: 'absolute' }"
                        :mask="false"
                    >
                        <a-form-model
                            :model="chartsForm"
                            :label-col="labelCol"
                            :wrapper-col="wrapperCol"
                            v-if="hidenForm"
                            class="form-wrap"
                            :colon="false"
                        >
                            <div class="form-title">数据可视化</div>
                            <div class="form-desc">请先选择数据项、统计项等条件，再选择合适的图形展现可视化</div>
                            <div class="form-type">
                                <div class="form-type-title">图表类型</div>
                                <div class="form-type-select">
                                    <a-form-model-item label="图形">
                                        <a-select
                                            default-value="pillar"
                                            style="width: 150px"
                                            placeholder="请选择"
                                            v-model="chartsForm.type"
                                            :getPopupContainer="
                                              triggerNode => {
                                             return triggerNode.parentNode || document.body;
                                             }"
                                        >
                                            <a-select-option value="pillar">柱状图</a-select-option>
                                            <a-select-option value="pie">饼状图</a-select-option>
                                            <a-select-option value="line">折线图</a-select-option>
                                        </a-select>
                                    </a-form-model-item>
                                </div>
                            </div>
                            <div class="form-contions">
                                <div class="form-contions-title">条件</div>
                                <div class="data-item">
                                    <a-form-model-item label="数据项">
                                        <a-select
                                            style="width: 150px"
                                            v-model="chartsForm.dataParam"
                                            placeholder="请选择"
                                            :getPopupContainer="
                                              triggerNode => {
                                             return triggerNode.parentNode || document.body;
                                             }"
                                        >
                                            <a-select-option
                                                v-for=" (item, index) in columns"
                                                :value="item.dataIndex"
                                                :key="index"
                                            >{{item.title}}</a-select-option>
                                        </a-select>
                                    </a-form-model-item>
                                </div>
                                <div class="stat-item">
                                    <a-form-model-item label="统计项">
                                        <a-select
                                            style="width: 150px"
                                            v-model="chartsForm.countParam"
                                            placeholder="请选择"
                                            :getPopupContainer="
                                              triggerNode => {
                                             return triggerNode.parentNode || document.body;
                                             }"
                                        >
                                            <a-select-option
                                                v-for=" (item, index) in columns"
                                                :value="item.dataIndex"
                                                :key="index"
                                            >{{item.title}}</a-select-option>
                                        </a-select>
                                    </a-form-model-item>
                                </div>
                                <div class="data-number">
                                    <a-form-model-item label="数据量">
                                        <a-select
                                            style="width: 150px"
                                            v-model="chartsForm.dataNum"
                                            placeholder="请选择"
                                            :getPopupContainer="
                                              triggerNode => {
                                             return triggerNode.parentNode || document.body;
                                             }"
                                        >
                                            <a-select-option value="10">10</a-select-option>
                                            <a-select-option value="20">20</a-select-option>
                                            <a-select-option value="30">30</a-select-option>
                                        </a-select>
                                    </a-form-model-item>
                                </div>
                                <div class="sort">
                                    <a-form-model-item label="排序" prop="resource">
                                        <a-radio-group v-model="chartsForm.sortType">
                                            <a-radio value="1">降序</a-radio>
                                            <a-radio value="2">升序</a-radio>
                                        </a-radio-group>
                                    </a-form-model-item>
                                </div>
                            </div>
                            <div class="form-btn">
                                <span @click="handleFormShow">关闭</span>
                                <span @click="ShowVisual">生成图表</span>
                            </div>
                        </a-form-model>
                    </a-drawer>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
ChartsPillar
import ChartsPie from '../components/ChartsPie.vue'
import ChartsLine from '../components/ChartsLine.vue'
import ChartsPillar from '../components/ChartsPillar.vue'
import { GetVisualizationTable, GetVisualizationData } from '../api'
import { formatTime, getLable } from '@/utils/tools'
export default {
    data() {
        return {
            chartsData: {},
            chartType: '',
            visData: {}, //可视化所有数据
            chooseItemValues: [],
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
            chartsForm: {
                //切换图表的表单
                type: undefined,
                countParam: undefined,
                dataNum: undefined,
                dataParam: undefined,
                sortType: '1',
                id: this.$route.query.id
            },
            isShowVisibleForm: true, //表格隐藏
            formVisible: true, // 弹窗隐藏
            hidenForm: true, // 弹窗内的表单隐藏
            goBack: false,
            tableData: [],
            columns: [],
            headList: {}
        }
    },
    components: {
        ChartsPie,
        ChartsLine,
        ChartsPillar
    },
    created() {
        this.loadTable()
    },
    mounted() {},

    methods: {
        formatTime,
        // 表头数据
        getHeadeList() {
            let arr = []
            for (var key in this.headList) {
                arr.push({
                    title: this.headList[key],
                    dataIndex: key,
                    key: key,
                    width: 200,
                    ellipsis: true,
                })
            }
            return arr
        },

        // 获取表格内容
        loadTable() {
            let id = this.$route.query.id
            GetVisualizationTable({ id })
                .then(res => {
                    this.visData = res
                    this.tableData = res.previewData
                    this.headList = res.tableHeader
                    this.columns = this.getHeadeList()
                })
                .catch(err => {
                    console.log(err)
                    this.$message.error('数据获取失败')
                })
        },
        handleFormShow() {
            this.formVisible = !this.formVisible
            this.hidenForm = !this.hidenForm
        },
        // 展示echarts
        async ShowVisual() {
            if (this.chartsForm.type == undefined) {
                this.$message.info('请选择图表类型')
                return
            } else if (this.chartsForm.dataParam == undefined) {
                this.$message.info('请选择数据项')
                return
            } else if (this.chartsForm.countParam == undefined) {
                this.$message.info('请选择统计项')
                return
            } else if (this.chartsForm.dataNum == undefined) {
                this.$message.info('请选择数据量')
                return
            }
            this.isShowVisibleForm = false
            this.goBack = true
            if (this.chartsForm.type == 'pie') {
                this.chartType = 'pie'
            } else if (this.chartsForm.type == 'line') {
                this.chartType = 'line'
            } else {
                this.chartType = 'pillar'
            }
            // 获取echahrt图标数据
            await GetVisualizationData(this.chartsForm)
                .then(res => {
                    this.chartsData = res
                })
                .catch(err => {
                    console.log(err)
                    this.$message.error('数据获取失败')
                })
        },

        // 返回列表
        goFromShow() {
            this.isShowVisibleForm = true
            this.goBack = false
        }
    }
}
</script>

<style scoped lang="scss">
.container {
    max-width: 1200px;
    min-width: 496px;
    margin: 0 auto;
    padding: 36px 0 16px 0;
    .wrap {
        position: relative;
        background-color: #fff;
        border: 1px solid #dbdbdb;
        margin-bottom: 20px;
        .title {
            font-weight: bold;
            font-size: 20px;
            padding: 5px 5px;
            color: #0591fd;
        }
        .desc {
            border: 1px solid #e5e5e5;
            border-right: none;
            border-left: none;
            padding: 10px;
            margin: 10px 0;
            color: #949494;
        }
        .data-item-wrap {
            height: 40px;
            padding-right: 10px;
            border-bottom: 1px solid #dbdbdb;
            .data-item {
                margin-left: 20px;
                float: left;
            }
            .visual-btn {
                float: right;
                span {
                    background-color: #43a3f3;
                    display: inline-block;
                    height: 30px;
                    color: #fff;
                    text-align: center;
                    line-height: 30px;
                    margin-left: 10px;
                    padding: 0 10px;
                }
            }
        }
        // 视图表单部分
        main {
            position: relative;
            overflow: hidden;
            /deep/.data-visual {
                min-height: 500px;
                margin-top: 12px;
                /deep/ table {
                    color: #000025;
                    border-collapse: collapse;
                    border-left: none;
                    border-right: none;
                    th,
                    td {
                        height: 40px !important;
                        padding: 0 !important;
                        padding-left: 16px !important;
                    }
                    td {
                        border-left: none;
                        border-right: none;
                        max-width: 150px;
                        word-wrap: break-word;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        cursor: pointer;
                    }

                    tr {
                        height: 40px !important;
                    }
                }
                .ant-table-body {
                    &::-webkit-scrollbar {
                        height: 10px;
                    }
                    &::-webkit-scrollbar-thumb {
                        border-radius: 8px;
                        // -webkit-box-shadow: inset 0 0 5px #ffffff;
                        background: #cccccc;
                        // border: solid 1px #d5d6e3;
                    }
                    &::-webkit-scrollbar-track {
                        // -webkit-box-shadow: 0;
                        // border-radius: 8px;
                        // border: solid 1px #d5d6e3;
                        background: #f6f6f6;
                    }
                }
            }
            /deep/.chartFrom {
                .ant-drawer-content-wrapper {
                    box-shadow: none;
                    .ant-drawer-body {
                        margin: 0;
                        padding: 0;
                        .form-wrap {
                            width: 320px;
                            height: 100%;
                            border: 1px solid #e5e5e5;
                            margin-left: 10px;
                            margin-top: 12px;
                            .form-title {
                                background-color: #508cd2;
                                color: white;
                                height: 32px;
                                font-size: 17px;
                                padding-top: 5px;
                                padding-left: 15px;
                            }
                            .form-desc {
                                font-size: 14px;
                                margin: 10px 20px 0 20px;
                            }
                            .form-type {
                                height: 90px;
                                margin: 10px 20px 0;
                                background-color: #eeeeee;
                                .form-type-title {
                                    color: #006ec3;
                                    text-align: center;
                                    padding-top: 10px;
                                    font-weight: bold;
                                }
                            }
                            .form-type-select {
                                .ant-form-item {
                                    margin-top: 10px;
                                }
                            }
                            .form-contions {
                                height: 100%;
                                margin: 12px 20px;
                                background-color: #eeeeee;
                                .form-contions-title {
                                    color: #006ec3;
                                    text-align: center;
                                    padding-top: 10px;
                                    font-weight: bold;
                                }
                                .data-item,
                                .stat-item,
                                .data-number,
                                .sort {
                                    .ant-form-item {
                                        margin: 5px 0;
                                    }
                                }
                            }
                            .form-btn {
                                margin: 15px 0;
                                font-size: 14px;
                                overflow: hidden;
                                span {
                                    float: right;
                                    margin-right: 20px;
                                    height: 30px;
                                    font-size: 15px;
                                    color: #fff;
                                    background-color: #43a3f3;
                                    border-color: #43a3f3;
                                    text-align: center;
                                    line-height: 30px;
                                    padding: 0 10px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>