var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{staticClass:"cla-modal",attrs:{"width":"1000px","title":_vm.title,"visible":_vm.visible,"okText":"提交"},on:{"cancel":_vm.handleModalCancel,"ok":_vm.handleConfirm}},[_c('div',{staticClass:"main"},[_c('p',[_c('SameStyleBox',{attrs:{"title":"申请信息"}})],1),_c('a-form',{staticClass:"form-wrap",attrs:{"hideRequiredMark":true}},[_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"form-item",attrs:{"label":"申请用户名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                    'username',
                                    {
                                        rules: [{ required: true, message: '申请用户名称不能为空！' }]
                                    }
                                ]),expression:"[\n                                    'username',\n                                    {\n                                        rules: [{ required: true, message: '申请用户名称不能为空！' }]\n                                    }\n                                ]"}],attrs:{"placeholder":"请输入申请用户名称"}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":"申请用户邮箱"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                    'email',
                                    {
                                        rules: [
                                            {
                                                type: 'email',
                                                message: '邮箱格式不正确'
                                            },
                                            { required: true, message: '申请用户邮箱不能为空！' }
                                        ]
                                    }
                                ]),expression:"[\n                                    'email',\n                                    {\n                                        rules: [\n                                            {\n                                                type: 'email',\n                                                message: '邮箱格式不正确'\n                                            },\n                                            { required: true, message: '申请用户邮箱不能为空！' }\n                                        ]\n                                    }\n                                ]"}],attrs:{"placeholder":"请输入申请用户邮箱"}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":"数源部门"}},[_c('a-input',{attrs:{"readonly":"","defaultValue":"舟山市大数据局"}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":"目录名称"}},[_c('a-input',{attrs:{"readonly":"","defaultValue":"信息资源名称"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"form-item",attrs:{"label":"申请用户电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                    'phone',
                                    {
                                        rules: [
                                            {
                                                pattern: /^1[0-9]{10}$/,
                                                message: '请输入正确的手机号'
                                            },
                                            { required: true, message: '申请用户电话不能为空！' }
                                        ]
                                    }
                                ]),expression:"[\n                                    'phone',\n                                    {\n                                        rules: [\n                                            {\n                                                pattern: /^1[0-9]{10}$/,\n                                                message: '请输入正确的手机号'\n                                            },\n                                            { required: true, message: '申请用户电话不能为空！' }\n                                        ]\n                                    }\n                                ]"}],attrs:{"placeholder":"请输入申请用户电话"}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":"应用场景"}},[_c('a-textarea',{staticClass:"form-item-situation",attrs:{"placeholder":"非必填","rows":4}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":12}}),_c('a-col',{attrs:{"span":12}})],1)],1)],1),_c('a-icon',{attrs:{"slot":"closeIcon","type":"close-circle"},slot:"closeIcon"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }